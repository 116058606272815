<template>
	<main class="container">
		<section class="content">
			<h1>Animal Assisted Therapy</h1>
			<p>
				Animal Assisted Therapy (AAT) is a relatively new practice in Canada, and is widespread and implemented in other
				countries (i.e USA, UK, Israel). It's active and experiential approach is becoming popular amongst all.
			</p>
			<p>
				Animal assisted work is based on definitions of the International Association of Human-Animal Interaction
				Organizations (IAHAIO).<br />
				“Animal Assisted Therapy (AAT) is a goal oriented, planned and structured therapeutic intervention directed and/or
				delivered by health, education or human service professionals, including e.g. psychologists and social workers.
				Intervention progress is measured and included in professional documentation. AAT is delivered and/or directed by a
				formally trained (with active licensure, degree or equivalent) professional with expertise within the scope of the
				professionals’ practice. AAT focuses on enhancing physical, cognitive, behavioral and/or socio-emotional functioning
				of the particular human recipient either in the group or individual setting. The professional delivering AAT (or the
				person handling the animal under the supervision of the human service professional) must have adequate knowledge about
				the behavior, needs, health and indicators and regulation of stress of the animals involved”. IAHAIO WHITE PAPER 2014,
				updated for 2018
			</p>
			<p>
				AAT supports clients with a multitude of goals, as it enhances an individual's physical, emotional and social
				well-being, thus improving self-esteem, reducing anxiety, providing help with PTSD symptoms and facilitating healing.
			</p>
			<p>
				AAT has been used to promote socialization, provide distraction, create a comfortable environment that encourages the
				client to express themselves, and enhance trust. It can be utilised with most mind/body therapies.
			</p>
			<p>
				Mental-emotional level; the encounter with the animal is a mirror of the soul. The client's mood, feelings, and
				behaviors reflected through the animal. It allows the client to experience their internal emotions through something
				external from themselves.
			</p>
			<p>
				Working with animals and their presence promotes relaxation for the client and reduces their stress and anxiety. It
				enables and facilitates the expression of emotions, releasing and redirecting those emotions towards the animal who
				then takes those emotions and frees the client of those negative feelings.
			</p>
			<p>
				The connection to the animal, treating it and interacting with it, gives a sense of meaning and increases a sense of
				peace and quiet. It provides confidence and pride, strengthening the sense of ability and self-esteem.The connection
				that is created between the animal and the client throughout this process relieves loneliness, where the animal
				becomes a model on how the client can interact with different people in their own environment. Through caring for an
				animal's needs, experiencing its reactions, its fears, its potential for anger, we can work on processing client’s
				emotions, foster self-control and emotional regulation. Therefore, the meetings between the client and animal can help
				treat anxiety, depression, anger, fear and more.
			</p>
			<p>
				Social level; the animal reflects models of communication and relationships, helping the client to understand their
				connections with various people in their life. Working with a variety of animals with different characteristics and
				behavior patterns, symbolizes different aspects of life. This promotes understanding of own behavior and provides
				opportunities to "read" social situations. The animal becomes a mediator and bridge between the client and their
				immediate environment. Getting to know the animal and developing that communication and relationship with the animal
				(not always verbally), brings a better level of sensitivity, empathy, and acknowledgement of other's needs. It
				improves social skills and interpersonal communication, creating meaningful connections more naturally. This allows
				the client to have an alternative experience than the one they have experienced in the past.
			</p>
			<p>
				The result of learning, processing patterns and internalizing that information with the animal, decreases the levels
				of psychological distress that might have been developed due to difficult past social interaction. This therapeutic
				bond develops the client's awareness of others, their compassion, their empathy, their patience and their listening
				skills. Animal- client connection with therapist facilitation encourages accountability, commitment and the ability to
				plan and implement client's goals. It promotes effective organization, concentration, delayed gratification as well as
				cooperation and teamwork.
			</p>
			<p>
				Physiological level; the encounters with animals produce experiences that sharpen the senses. The encounter with the
				animal enables different sensational experiences - furry, feathery, warm, prickly, smooth, cool. Touching and handling
				the animal helps accepting their own physical image, developing fine motor skills and coordination with significant
				rehabilitative aspects.
			</p>
			<p>
				In terms of experiential learning, the encounter with animals and learning about them exposes the client to knowledge,
				research, and observations. It develops thinking and learning skills that encourage natural curiosity as well as
				experiencing success every time they accomplish a new task with the animal.
			</p>
			<p>
				One of the most fundamental advantages of animal assisted therapy over other therapeutic modalities is that it
				provides the client a much-needed opportunity to give affection as well as receive it. It is this reciprocity - rare
				among medical therapies - that makes AAT a unique, and valuable route to healing.
			</p>
		</section>
	</main>
</template>

<script>
export default {
	name: 'Aat',
	components: {}
};
</script>
<style lang="scss" scoped>
// .container {
// 	display: flex;
// 	flex-grow: 1;
// 	flex-direction: column;
// }
</style>

<template>
	<div class="container">
		<BRow>
			<BCol lg="auto" md="auto">
				<h1>Our Team</h1>

				<p>
					Over the past 16 years I've worked with children, youth and families. People from different backgrounds and
					abilities, different voices and stories, needs and age groups. Throughout my practice as an Animal Assisted
					Therapist I've been supporting professionals and clients through a variety of organizations such as;
					Department of Education- daycares and schools. Department of Social Affairs and Community Services, victim
					services, care facilities, nursing homes and addiction units.
				</p>
				<p>
					I'm a believer that most humans grow and heal best by doing. Working together with animals and for the animals
					offers us a hands-on, active experience, safe and comfortable for all, at times challenging as any process of
					growth and development. Each animal, like humans, has its distinct personality, it provides us the needed
					space with therapists navigation to project and analyze behaviours and situations while working on our goals.
				</p>
				<p>
					Please don't hesitate to reach out for further conversation and learn how Animal Assisted Therapy services can
					help.
				</p>
			</BCol>
		</BRow>
		<!-- <div class="container__text">
			<h5>About Me</h5>

			<p>
				Over the past 15 years I've worked with different age groups and organizations,such as schools, foster care,
				department of social affairs, victims services, residential care, nursing homes and addiction units.
			</p>
			<p>
				I'm a believer that most humans grow and heal best by doing. Working with animals offers us a hands on, active
				experience. Each animal, like humans has it's distinct personality, it provides us the needed space to project and
				analyze behaviours and situations while working on our goals.
			</p>
		</div> -->
		<BRow>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/20180514_165336_edited.jpg" title="Yana with Gucci and Churchill" />
				</aside>
			</BCol>
			<BCol lg="8" md="auto">
				<h4>Certifications:</h4>
				<ul>
					<li>Masters of Education and Leadership, Tel Aviv University</li>
					<li>
						Diploma in Animal Assisted Therapy, Magid school for Animal Assisted Interventions of the Hebrew
						University Jerusalem, recognized by International Association of Human-Animal Interaction
						Organizations (IAHAIO) and International Society for Animal Assisted Therapy (ISAAT)
					</li>
				</ul>
				<p class="pl-3">
					Recognized by the Nova Scotia Legislature in April 2019<br />
					<cite
						>"Yana is a tremendous support for her patients and is also an excellent coach for caregivers, social
						workers, and family members..."</cite
					>
					Honorable Patricia Arab, MLA for Fairview- Clayton Park (2019)
				</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol lg="12" md="auto">
				<!-- <h5>Meet some of the staff</h5> -->
				<div class="container">
					<section class="p-md-3 mx-md-5 text-center text-lg-left">
						<h5 class="text-center font-weight-bold mb-0 pb-0">Meet some of the staff</h5>
					</section>
				</div>
			</BCol>
		</BRow>
		<BRow>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/sammy.jpg" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/churchill.png" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right" style="height: 354px;">
					<img alt="aat" src="../assets/henry_crop.png" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/noya.png" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/freud.jpg" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/lumpa.png" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/Humphrey4.jpeg" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/Bernardo1.jpeg" style="height: 340px;" />
				</aside>
			</BCol>
			<BCol lg="4" md="auto">
				<aside class="right">
					<img alt="aat" src="../assets/chewie1.png" style="height: 340px;" />
				</aside>
			</BCol>
		</BRow>

		<!-- <BCol lg="4" md="auto">
				<figure class="figure">
					<img src="../assets/sammy.jpg" class="figure-img img-fluid z-depth-1" alt="..." style="width: 400px" />
					<figcaption class="figure-caption">A caption for the above image.</figcaption>
				</figure>
			</BCol>
			<BCol lg="4" md="auto">
				<figure class="figure">
					<img src="../assets/churchill.png" class="figure-img img-fluid z-depth-1" alt="..." style="width: 400px" />
					<figcaption class="figure-caption">A caption for the above image.</figcaption>
				</figure>
			</BCol>
			<BCol lg="4" md="auto">
				<figure class="figure">
					<img src="../assets/freud.jpg" class="figure-img img-fluid z-depth-1" alt="..." style="width: 400px" />
					<figcaption class="figure-caption">A caption for the above image.</figcaption>
				</figure>
			</BCol>
			<BCol lg="4" md="auto">
				<figure class="figure">
					<img src="../assets/henry_crop.png" class="figure-img img-fluid z-depth-1" alt="..." style="width: 400px" />
					<figcaption class="figure-caption">A caption for the above image.</figcaption>
				</figure>
			</BCol>
			<BCol lg="4" md="auto">
				<figure class="figure">
					<img src="../assets/noya.png" class="figure-img img-fluid z-depth-1" alt="..." style="width: 400px" />
					<figcaption class="figure-caption">A caption for the above image.</figcaption>
				</figure>
			</BCol>
		 -->

		<!-- <div class="container py-5 my-5">
			<section class="p-md-3 mx-md-5 text-center text-lg-left">
				<h2 class="text-center font-weight-bold mb-4 pb-1">Our Team</h2>
				<p class="text-center lead mb-5 pb-2 text-muted">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
				</p>
				<div class="row">
					<div class="col-lg-4 mb-4">
						<div class="card">
							<div class="avatar w-100 white d-flex justify-content-center align-items-center">
								<img src="../assets/sammy.jpg" class="img-fluid" />
							</div>
							<div class="card-body">
								<p class="font-weight-bold my-2">Veronica Smith</p>
								<p class="text-muted">Creative Director</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mb-4">
						<div class="card">
							<div class="avatar w-100 white d-flex justify-content-center align-items-center">
								<img src="../assets/churchill.png" class="img-fluid" />
							</div>
							<div class="card-body">
								<p class="font-weight-bold my-2">Sally Thompson</p>
								<p class="text-muted">Frontend Developer</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mb-4">
						<div class="card">
							<div class="avatar w-100 white d-flex justify-content-center align-items-center">
								<img src="../assets/freud.jpg" class="img-fluid" />
							</div>
							<div class="card-body">
								<p class="font-weight-bold my-2">Anna Smith</p>
								<p class="text-muted">Backend Developer</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'About',
	components: {}
};
</script>
<style lang="scss" scoped>
.container {
	//border: 1px solid blue;
	//padding: 20px 40px 40px;
	//max-width: 640px;
	display: flex;
	flex-direction: column;

	// &__text {
	// 	flex: 1 1 0%;
	// }
	// &__left {
	// 	display: flex;

	// 	@media screen and (max-width: 992px) {
	// 		flex-wrap: wrap;
	// 	}
	// 	&__div {
	// 		flex: 1 1 0%;
	// 	}
	// &__image {
	// 	//margin: 0px 0px 0px 0px;
	// 	max-width: 90%;
	// 	align-self: flex-start;
	// 	object-fit: contain;
	// }
	//}
}
.right {
	display: flex;
}

.right img {
	width: 100%;
	//object-fit: contain;
	border-radius: 4px;
	margin-top: 4px;
	max-height: 350px;
}
</style>

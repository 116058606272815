<template>
	<div class="container mt-5">
		<div class="row">
			<div class="col-md-8">
				<h1 class="mb-4">
					Understanding Anxiety Disorders in Children and Youth: Positive Effects of Animal-Assisted Therapy
				</h1>
				<p class="text-muted">Yana Gold, March 1, 2023</p>
				<!-- <img src="https://picsum.photos/800/400" class="img-fluid my-4" alt="Responsive UI with Vue.js" /> -->
				<!-- <img class="card-img-top" src="../assets/buffalo.jpeg" alt="Card image cap" /> -->
				<p>
					Animal-assisted therapy (AAT) is a comfortable approach that involves human-animal interaction in a
					therapeutic setting. It has been shown to be effective in supporting people with a variety of health
					conditions and challenges, including anxiety disorders. This post will provide an outline of most common
					anxiety disorders in children and youth, and share the story Sal (pseudonym) providing insights into how AAT
					can support children and youth with anxiety.
				</p>
				<p>
					<b><u> Disorders in Children and Youth</u></b
					><br />
					Fear is a natural and necessary emotion for our existence. It is the fear response that drives us to flee and
					find shelter in a situation of immediate danger. Facing immediate danger, fear is a warning, an alarm
					mechanism which leads us to caution and safety.
				</p>
				<p>
					Throughout our lives, we learn what to watch out for and what to fear, the warning and alarm mechanism is the
					outcome of this learning. However, like any alarm system, this system can malfunction. This malfunction may
					manifest itself in an extreme reaction of fear, turning into a phobia or an unfocused anxiety that does not
					have a specific tangible object from which the child seeks to escape.The feelings of fear or anxiety may be
					expressed in a way that does not correspond to the intensity of the actual risk, thus bringing unnecessary
					suffering or even preventing vital functioning. An anxiety disorder exists when an anxiety response that does
					not correspond to reality and the needs for functioning. In children, the anxiety disorder will manifest
					itself not only in the reactions of the child, but also in the reactions and attitude of their close
					environment.
				</p>
				<p>
					Here is a review of common anxiety disorders in children, with a reference to their caregivers.
				</p>
				<p>
					<b><u>Separation Anxiety</u></b
					><br />
					Children suffering from separation anxiety find it very difficult to stay away from their caregiver/s. They
					can be afraid that something bad will happen to them in the absence of their caregiver or that something bad
					will happen to the caregiver in their absence. Separation anxiety can arise even in situations of very short
					separation - being in the bathroom, brushing their teeth getting dressed, the adult is taking out the garbage.
					Separation anxiety can be related to a wide variety of other fears, such as fear of thieves, the dark,
					monsters, insects, etc. Separation from caregivers will usually be accompanied by crying, shouting, or
					begging. The caregiver is the primary target for the child&#39;s distress signals, similar to other anxiety
					issues and considered a crucial factor and the an element in the solution.
				</p>
				<p>
					<b><u>Generalized Anxiety</u></b
					><br />
					In this disorder, the child becomes a kind of an antenna, picking up any possible danger or threat. Children
					suffering from generalized anxiety worry all the time. Their own health or that of those close to them, the
					family&#39;s financial situation, success at school, the relationship between their loved ones, parents, wars,
					earthquakes and ecological disasters, etc. Caregivers might find themselves providing answers to the endless
					questions, and providing explanations. Usually, the answers does not relieve the anxiety sufficiently, and
					they have to repeat it many times. Sometimes the parent needs to enlist the support of experts and weighty
					evidence to calm the fears. It may appear as a dependency in caregiver’s calming efforts, and although its
					frequency might increase it won’t necessarily support the child’s feelings of calmness or perception of
					safety.
				</p>
				<p>
					<b><u>Social Anxiety</u></b
					><br />
					Social anxiety can be characterized as extreme shyness, which can limit a child&#39;s ability to engage in
					social interactions. Children with social anxiety often experience intense feelings of embarrassment or fear
					of being judged negatively, leading to avoidance of social situations. Physical symptoms such as blushing,
					trembling, and butterflies in the stomach may also be present before or during social contact. The scope of
					social anxiety can vary, from specific fears such as public speaking or fear of rejection, to a more
					comprehensive fear of any contact with people outside of the immediate family or their comfort circle. In its
					most severe form, social anxiety can result in complete or near- complete social withdrawal. In cases of
					social anxiety, caregivers may become the child&#39;s sole source of social interaction. Social anxiety
					challenges can arise during periods of transition in a child&#39;s life (family structure changes, school,
					kindergarten) or following traumatic events. While some children may overcome these challenges with support
					from their environment, persistent anxiety problems require professional help.
				</p>
				<p>
					<b><u>Specific Phobias</u></b
					><br />Specific Phobias Children with a specific phobia feel acute fear in certain situations, such as high
					places, closed places or dark places, or when they encounter certain factors such as dogs, snakes or insects.
					Although these fears are limited, and refer only to a specific situation or stimulus, they can lead to a
					significant disruption in everyday life. for example; a child with a phobia of dogs, can refuse to leave the
					house (&quot;How do I know that I won’t see a dog?”). Children will often demand or request certain
					&quot;discounts&quot; in their functioning; cancel trips, not going to certain areas of the house, or other
					situations, in which there may be an encounter with the phobia. Caregivers guarantee protection from the
					frightening event however this role can have far-reaching manifestations. For example, a youth who is afraid
					of dogs and manages to convince their caregivers to drive them everywhere and not to go outside alone.
				</p>
				<p>
					<b><u>Obsessive Compulsive Disorder (OCD)</u></b
					><br />
					OCD exists when one suffers from disturbing and threatening thoughts or feelings that penetrate their
					consciousness and force him to perform various actions in order to free himself from the distress they cause.
					Typical disturbing thoughts are worry about contamination by bacteria or contracting diseases, worry worry
					about harm if the child does not perform a certain action, disaster will befall him or his family members, and
					so on. Examples of actions that children feel obligated to perform to relieve distress, or to keep disaster
					away, are touching objects, repeated examinations, cleaning rituals (for example, prolonged hand washing),
					arranging objects in a certain and strict order, dressing in a certain order and more. In this disorder, the
					caregivers sometimes required to be a part of the rituals and often become permanent secondary players in the
					child&#39;s compulsive rituals.
				</p>
				<p>
					<b><u>AAT case description-</u></b
					><br />
					personal information and details were changed for confidentiality reasons.
				</p>
				<p>
					Sal (pseudonym) 10-year-old boy, referred to AAT by his parents. The family was seeking help for Sal to
					overcome social anxiety and generalized anxiety. Sal’s parents shared that his anxieties have and impact on
					Sal’s daily; limiting his daily functions such as- having difficulty leaving the house, creating social
					relationships, participating in enrichment activities and sports, sleeping in his own room and bed
					independently, and having regular attendance at school, despite support from the pedagogical staff.
				</p>
				<p>
					First session- Sal entered the AAT space with his mom, and after becoming familiar with the animals and the
					physical space, Sal agreed to say goodbye to his mom until the end of the session. The beginning of the
					therapeutic process focused on building trust, creating therapist- client- animals triangular connection and
					establishing therapeutic goals. The process was gradual and natural. It started with getting to know the
					animals, exploring the surroundings, learning about the space, its inhabitants and performing various
					operations for and with them. Sal showed great interest and curiosity during the sessions, displaying
					&quot;boldness&quot; by independently accessing cages, opening them, lifting the animals with care, holding,
					petting, grooming, building, creating and organizing their spaces. Initially, the ‘doing’ and being active
					served as an escape for Sal, a kind of refuge on the eve of his difficulty expressing himself verbally and
					addressing his anxieties, which he saw as a weakness. Receiving feedback from the animals shifted Sal’s focus
					giving him the ability to speak for the animals and project his emotions on them, allowing us to address his
					comfort, discomfort, fears, securities, wants and various past and present events.
				</p>
				<p>
					Through these actions, we emphasized Sal’s abilities and provided a safe space for him to raise internal
					conflicts and concerns from his daily life. Sal was able to connect to his inner strengths, while expressing
					interest in animals, especially Henry, an Australian Bearded Dragon. Despite being afraid of reptiles, Sal
					found Henry non-threatening, non demanding, and felt that he was friendly and always smiling. Sal saw Henry as
					fascinating and magical creature. Within a few sessions after meeting Henry, Sal was able to overcome the
					feelings of fear and disgust with support and navigation, he challenged himself to feed Henry. Leading to it
					was observing Henry and learning about his nutrition, creating food dishes and trying them out. Part of this
					process was exploring what Sal can do for himself if this is what he can do for Henry and his comfort.
				</p>
				<p>
					Through working together with Sal and the animals in the space, interacting, establishing relationships,
					creating opportunities for empowerment while staying active and ’doing’. Sal was observing the reality in the
					animals’ world, learning about them and himself. During the process we maintained on-going connection with
					Sal’s family and guidance through follow-up meetings and mutual session time. Sal began to achieve the first
					steps of the therapeutic goals. He was able to transition to his room alone, make a presentation in front of
					the class about bearded dragons with Henry’s presence and support, and register for a team sport activity.
				</p>
				<p>
					Anxiety disorders can be effectively treated, sometimes with general guidance for the caregivers, and in other
					cases, with systematic professional help for the child, caregivers, or both. It&#39;s important to note that
					different treatments may work better for different individuals, and a combination of approaches may be
					necessary for some. It’ is recommended to consult with a professional to determine the most effective/suitable
					treatment.
				</p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Article1',
	components: {},
	props: {},
	computed: {}
};
</script>
